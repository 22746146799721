import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';

const Login = () => import(/* webpackChunkName: "login-chunk" */ '@/views/Login.vue');
const Default = () => import(/* webpackChunkName: "default-chunk" */ '@/views/Default.vue');
const Home = () => import(/* webpackChunkName: "home-chunk" */ '@/views/Home.vue');
const RecuperoPassword = () => import(/* webpackChunkName: "recPsw-chunk" */ '@/views/RecuperoPassword.vue');
const Registrazione = () => import(/* webpackChunkName: "reg-chunk" */ '@/views/Registrazione.vue');
const Profilo = () => import(/* webpackChunkName: "profile-chunk" */ '@/views/Profilo.vue');
const CatalogoStagionale = () => import(/* webpackChunkName: "catStag-chunk" */ '@/views/CatalogoStagionale.vue');
const CatalogoRiassortimento = () => import(/* webpackChunkName: "catRias-chunk" */ '@/views/CatalogoRiassortimento.vue');
const CarrelloStagionale = () => import(/* webpackChunkName: "carStag-chunk" */ '@/views/CarrelloStagionale.vue');
const CarrelloRiassortimento = () => import(/* webpackChunkName: "carRias-chunk" */ '@/views/CarrelloRiassortimento.vue');
const SchedaProdotto = () => import(/* webpackChunkName: "scProd-chunk" */ '@/views/SchedaProdotto.vue');
const ModelliRiassortimento = () => import(/* webpackChunkName: "modRiass-chunk" */ '@/views/ModelliRiassortimento.vue');
const ModelliStagionale = () => import(/* webpackChunkName: "modStag-chunk" */ '@/views/ModelliStagionale.vue');
const SearchResultsStagionale = () => import(/* webpackChunkName: "searchStag-chunk" */ '@/views/SearchResultsStagionale.vue');
const SearchResultsRiassortimento = () => import(/* webpackChunkName: "searchRiass-chunk" */ '@/views/SearchResultsRiassortimento.vue'); 
const Pagina = () => import(/* webpackChunkName: "pagina-chunk" */ '@/views/Pagina.vue');
const PaginaNoLogin = () => import(/* webpackChunkName: "pagina-chunk" */ '@/views/PaginaNoLogin.vue');
const SchedaOrdine = () => import(/* webpackChunkName: "pagina-chunk" */ '@/views/SchedaOrdine.vue');

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/PasswordRecovery',
    name: 'RecuperoPassword',
    component: RecuperoPassword
  },
  {
    path: '/Registration',
    name: 'Registrazione',
    component: Registrazione
  },
  {
    path: '/PrivacyPolicy/:idPagina',
    name: 'PaginaPrivacy',
    component: PaginaNoLogin
  },
  {
    path: '/TerminiCondizioni/:idPagina',
    name: 'PaginaTermini',
    component: PaginaNoLogin
  },
  {
    path: '/default',
    name: "Default",
    component: Default,
    beforeEnter(to, from, next){
      if(store.getters.isLoggedIn){
        next();
      }
      else{
        next('/');
      }
    },    
    children:[
      {
        path: '',
        name: "Home",
        component: Home,
        meta: {
          breadCrumbs:[
            {
              to: '/',
              text: 'HomepageArianna'
            }
          ]
        },
      },
      {
        path: 'Profile/:where',
        name: "Profilo",
        component: Profilo,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '',
              text: 'ProfiloArianna'
            }
          ]
        },
      },
      {
        path: '/catalogoStagionale',
        name: "CatalogoStagionale",
        component: CatalogoStagionale,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '/catalogoStagionale',
              text: 'SeasonalCatalogArianna'
            }
          ]
        },
      },
      {
        path: '/catalogoRiassortimento',
        name: "CatalogoRiassortimento",
        component: CatalogoRiassortimento,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '/catalogoRiassortimento',
              text: 'RestockCatalogArianna'
            }
          ]
        },
      },
      {
        path: '/carrelloStagionale',
        name: "CarrelloStagionale",
        component: CarrelloStagionale,
        meta: {
          breadCrumbs:[
            {
              to:'/default',
              text: 'HomepageArianna'
            },
            {
              to: '',
              text: 'CartArianna'
            }
          ]
        }
      },
      {
        path: '/carrelloRiassortimento',
        name: "CarrelloRiassortimento",
        component: CarrelloRiassortimento,
        meta: {
          breadCrumbs:[
            {
              to:'/default',
              text: 'HomepageArianna'
            },
            {
              to: '',
              text: 'CartArianna'
            }
          ]
        }
      },
      {
        path: 'modelliStagionale/:idCategoria',
        name: "ModelliStagionale",
        component: ModelliStagionale,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '/catalogoStagionale',
              text: 'SeasonalCatalogArianna'
            },
            {
              to: '',
              text: 'SeasonalModelsArianna'
            }
          ]
        }
      },
      {
        path: 'modelliRiassortimento/:idCategoria',
        name: "ModelliRiassortimento",
        component: ModelliRiassortimento,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '/catalogoRiassortimento',
              text: 'RestockCatalogArianna'
            },
            {
              to: '',
              text: 'RestockModelsArianna'
            }
          ]
        }
      },
      {
        path: 'schedaProdotto/:idProdotto&:catalogo&:categoria',
        name: "SchedaProdotto",
        component: SchedaProdotto,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '/catalogoRiassortimento',
              text: 'RestockCatalogArianna'
            },
            {
              to: '/catalogoStagionale',
              text: 'SeasonalCatalogArianna'
            },
            {
              to: '',
              text: 'ModelArianna'
            }
          ]
        }
      },
      {
        path: 'risultatiRicerca/:searchKey?',
        name: "SearchResultsStagionale",
        component: SearchResultsStagionale,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '',
              text: 'SearchResultsArianna'
            }
          ]
        }
      },
      {
        path: 'risultatiRicerca/:searchKey?',
        name: "SearchResultsRiassortimento",
        component: SearchResultsRiassortimento,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to: '',
              text: 'SearchResultsArianna'
            }
          ]
        }
      },
      {
        path: 'pagina/:idPagina?',
        name: "Pagina",
        component: Pagina,
        meta: {
          breadCrumbs:[
            {
              to: '/',
              text: 'Page'
            }
          ]
        },
      },
      {
        path: 'scheda-ordine/:idOrdine&:TpCat',
        name: "SchedaOrdine",
        component: SchedaOrdine,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'HomepageArianna'
            },
            {
              to:'/default/Profile/head',
              text: 'ProfiloArianna'
            },
            {
              to: '',
              text: 'OrderArianna'
            }
          ]
        }
      },
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
