export default class DatiLoginUtente{
    Azienda;
    Cognome;
    Email;
    Nome;
    RiassortimentoAbilitato;
    StagionaleAbilitato;
    expires_in;
    token;
    constructor(){}
}