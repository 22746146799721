import moment from "moment";
import CarrelloPayload from "@/models/Carrello/carrelloPayload.model.js";
import Carrello from "@/models/Carrello/carrello.model.js";
import WebApiService from "@/services/webapi.service.js";

const state = {
    carrelloRiass: null,
    carrelloStag: null,
    carrelloTmpStag: null,
    carrelloTmpRiass: null
}

const getters = {
    getCarrelloStag: (state) => {
        return state.carrelloStag ?? JSON.parse(sessionStorage.getItem("carrelloStag"));
    },
    getCarrelloRiass: (state) => {
        return state.carrelloRiass ?? JSON.parse(sessionStorage.getItem("carrelloRiass"));
    },
    getCarrelloTmpStag: (state) => {
        return state.carrelloTmpStag;
    },
    getCarrelloTmpRiass: (state) => {
        return state.carrelloTmpRiass;
    },
    getNumeroRigheRiass: (state) => {
        let carrello = state.carrelloTmpRiass;
        if(carrello != null && carrello.Righe != null){
            return carrello.Righe.length;
        }else{
            return 0;
        }
    },
    getNumeroRigheStag: (state) => {
        let carrello = state.carrelloTmpStag;
        if(carrello != null && carrello.Righe != null){
            return carrello.Righe.length;
        }else{
            return 0;
        }
    }
}

const mutations = {
    updateCarrelloRiassortimento: (state, carrello) => {       
        state.carrelloRiass = carrello;
        sessionStorage.setItem('carrelloRiass', JSON.stringify(state.carrelloRiass));
    },
    updateCarrelloStagionale: (state, carrello) => {       
        state.carrelloStag = carrello;
        sessionStorage.setItem('carrelloStag', JSON.stringify(state.carrelloStag));
    },
    ChangeArts: (state, data) => {
        let currentCarrello = null;
        if(data.TipoCarrello === 'Stag'){
            currentCarrello = state.carrelloTmpStag;
        }
        if(data.TipoCarrello === 'Riass'){
            currentCarrello = state.carrelloTmpRiass;
        }
        if(data.Quantita > 0){
            if(currentCarrello == null){
                currentCarrello = new Carrello();
                currentCarrello.Testata.DataCreazione = moment().format("YYYY-MM-DD HH:mm:ss");    
                currentCarrello.Testata.Valuta = 'eur';
            }
            let index = currentCarrello.Righe.findIndex(x =>{
                return (x.CodiceArticolo === data.CodiceArticolo && x.CodiceVariante === data.CodiceVariante)
            });
            let articolo = new CarrelloPayload(); 
            articolo.setDati(  
                data.CodiceArticolo,
                data.CodiceVariante,
                data.CodiceCategoria,
                data.Quantita
            );
            if(index != -1 ){
                currentCarrello.Righe.splice(index,1);
            }
            currentCarrello.Righe.push(articolo);
        }else{
            if(currentCarrello != null && currentCarrello.Righe != null){
                let index = currentCarrello.Righe.findIndex(x =>{
                    return (x.CodiceArticolo === data.CodiceArticolo && x.CodiceVariante === data.CodiceVariante)
                });
                currentCarrello.Righe.splice(index,1);
            }
        }
        if(data.TipoCarrello === 'Stag'){
            state.carrelloTmpStag = currentCarrello;
        }
        if(data.TipoCarrello === 'Riass'){
            state.carrelloTmpRiass = currentCarrello;
        }
    },
    RemoveCartStag: (state) => {
        state.carrelloStag = null;
        localStorage.removeItem("carrelloStag");
    },
    RemoveCartRiass: (state) => {
        state.carrelloRiass = null;
        localStorage.removeItem("carrelloRiass");
    },
    resetCarrelloTmpStag: (state) => {
        state.carrelloTmpStag = null;
    },
    resetCarrelloTmpRiass: (state) => {
        state.carrelloTmpRiass = null;
    },
}

const actions = {
    addItemToChartStag:({commit}, data) =>{
        if(data){
            data.data.TipoCarrello = 'Stag';
            commit("ChangeArts",data.data);
        }
    },
    addItemToChartRiass:({commit}, data) =>{
        if(data){
            data.data.TipoCarrello ='Riass';
            commit("ChangeArts",data.data);
        }
    },
    GetCarrelloRiassortimentoApi:({commit},data) =>{
        return WebApiService.getWLang("Carrello/GetCarrello", data.Token,{tpCart: "riass"},data.lingua).then(
            (data) =>{
                if(data){
                    let carrello = Object.assign(new Carrello(), data.Data);
                    commit("updateCarrelloRiassortimento",carrello);
                }
            }
        );
    },
    GetCarrelloStagionaleApi:({commit},data) =>{
        return WebApiService.getWLang("Carrello/GetCarrello", data.Token,{tpCart: "stag"},data.lingua).then(
            (data) =>{
                if(data){
                    let carrello = Object.assign(new Carrello(), data.Data);
                    commit("updateCarrelloStagionale",carrello);
                }
            }
        );
    },
    resetCarrello:({commit}) => {
        commit("resetCarrelloTmp");
    },
    resetCarrelloSrvRiass:({commit}) => {
        commit("RemoveCartRiass");
    },
    resetCarrelloSrvStag:({commit}) => {
        commit("RemoveCartStag");
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}