export default class LoginData{
    username;
    password;
    codShop;
    
    constructor(username, password,codShop){
        this.username = username;
        this.password = password;
        this.codShop = codShop
    }
}