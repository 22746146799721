import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster';
import vfm from 'vue-final-modal';

createApp(App)
.use(store)
.use(router)
.use(vfm)
.use(Toaster)
.mount('#app')
