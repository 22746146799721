import webapiService from "@/services/webapi.service";

const state = {
    province: null,
    nazioni: null
}

const getters = {
    getProvince: (state) => {
        return state.province ?? JSON.parse(sessionStorage.getItem("province"));
    },
    getNazioni: (state) => {
        return state.nazioni ?? JSON.parse(sessionStorage.getItem("nazioni"));
    }
}

const mutations = {
    setProvince:  (state, province) => {
        state.province = province;
        sessionStorage.setItem("province", JSON.stringify(state.province));
    },
    setNazioni:  (state, nazioni) => {
        state.nazioni = nazioni;
        sessionStorage.setItem("nazioni", JSON.stringify(state.nazioni));
    }
}

const actions = {
    setProvinceApi: ({commit}) => {
        return webapiService.getWApiKey("Risorse/Province").then(
            (data) => {
                if(data){
                    commit("setProvince", data.Data);
                }
            }
        );
    },
    setNazioniApi: ({commit}) => {
        return webapiService.getWApiKey("Risorse/Nazioni").then(
            (data) => {
                if(data){
                    commit("setNazioni", data.Data);
                }
            }
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}