import WebApiService from "@/services/webapi.service.js";
import CategoriaStagionale from "@/models/Categorie/categoriaStagionale.model.js";
import CategoriaRiassortimento from "@/models/Categorie/categoriaRiassortimento.model.js";

const state = {
    catStag: null,
    catRiass: null
}

const getters = {
    getCatStag: (state) =>{
        return state.catStag ?? JSON.parse(sessionStorage.getItem("stagionale"));
    },
    getCatRiass: (state) => {
        return state.catRiass ?? JSON.parse(sessionStorage.getItem("riassortimento"));
    }
}

const mutations = {
    setCatStag: (state, catListStag) =>{
        state.catStag = catListStag;
        sessionStorage.setItem('stagionale',JSON.stringify(state.catStag));
    },
    setCatRiass: (state, catListRiass) =>{
        state.catRiass = catListRiass;
        sessionStorage.setItem('riassortimento',JSON.stringify(state.catRiass));
    }
}
const actions = {
    getCatalogoStagApi: ({commit},data) =>{
        return WebApiService.getWLang("Categorie/CategorieStagionale", data.Token, null, data.Lingua).then(
            (data) =>{
                if(data && data.Data != null){
                    let catalogoStag = data.Data.map(o => {
                        return Object.assign(new CategoriaStagionale(), o)
                    });
                    commit("setCatStag",catalogoStag);
                }
            }
        );
    },
    getCatalogoRiassApi: ({commit},data) =>{
        return WebApiService.getWLang("Categorie/CategorieRiassortimento", data.Token, null, data.Lingua).then(
            (data) =>{
                if(data && data.Data != null){
                    let catalogoRiass = data.Data.map(o => {
                        return Object.assign(new CategoriaRiassortimento(), o)
                    });
                    commit("setCatRiass",catalogoRiass);
                }
            }
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}