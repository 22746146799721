import { createStore } from 'vuex';
import utente from './modules/utente.module';
import carrello from './modules/carrello.module.js';
import comboData from './modules/comboData.module.js';
import categorie from './modules/cataloghi.module.js';
import risorse from './modules/risorse.module.js';
import menu from './modules/menu.module.js';
import ordini from './modules/ordini.module.js'

export default createStore({
  modules:{
    utente,
    carrello,
    comboData,
    categorie,
    risorse,
    menu,
    ordini
  }
})
