import WebApiService from "@/services/webapi.service.js";

const state = {
    menu: null,
    listaPagine: null
}

const getters = {
    getMenu: (state) =>{
        return state.menu ?? JSON.parse(localStorage.getItem("menu"));
    }
}

const mutations = {
    setMenu: (state,listaMenu) =>{
        state.menu = listaMenu;
        localStorage.setItem('menu',JSON.stringify(state.menu));
    }
}

const actions = {
    getMenuApi: ({commit}, data) =>{
        return WebApiService.getWLang("Pagine/Menu",data.Token, null , data.Lingua).then(
            (data) => {
                if(data && data.Data != null){
                    let listaMenu = data.Data;
                    commit('setMenu',listaMenu);
                }
            }
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}