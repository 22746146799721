import WebApiService from "@/services/webapi.service.js";

const state = {
    ordini : null,   
}

const getters =  {
    getOrdini: (state)=> {
        return  state.ordini ??  JSON.parse(sessionStorage.getItem("ordini"));
    },
    /*getOridne: (state) => (codiceOrdine) => {
        let ordini = state.ordini;
        if(ordini == null){
            ordini = JSON.parse(sessionStorage.getItem("ordini"));
        }
        let or = ordini.find(item => item.Id === codPag);
        if (pg === null){
            let menuInfo = state.menuInfoAz;
            if(menuInfo == null){
                menuInfo = JSON.parse(sessionStorage.getItem("menuInfo"));
            }
            pg = menuInfo.SottoMenu.find(item => item.Id === codPag);
        }
    }*/
}

const mutations = {
    setOrdini: (state, ordini)  => {
        state.ordini = ordini;
        sessionStorage.setItem('ordini',JSON.stringify(state.ordini));
    }
}

const actions = {
    geOrdiniFromApi: ({commit}, data) => {       
        return WebApiService.getWLang("Ordini/OrdiniAll", data.Token, null, data.Lingua).then(
            (data) =>{
                if(data){
                    var ordini = data.Data;
                    commit("setOrdini",ordini);
                }
            }
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}