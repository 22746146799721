export default class ApiResponse{

    StatusCode;
    Data;
    Message;

    constructor(statusCode = null, data = null, message = null){
        this.StatusCode = statusCode;
        this.Data = data;
        this.Message = message;
    }   
}