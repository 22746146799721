export default class CarrelloPayload{
    CodiceArticolo;
    CodiceVariante;
    CodiceCategoria;
    Quantita;

    setDati(
        codiceArticolo,
        codiceVariante,
        codiceCategoria,
        quantita
    ){
        this.CodiceArticolo = codiceArticolo;
        this.CodiceVariante = codiceVariante;
        this.CodiceCategoria = codiceCategoria;
        this.Quantita = quantita;
    }
    constructor(){}
}