import WebApiService from "@/services/webapi.service.js";

const state = {
    resources : null,
    reload: false,
    message: null,
    pagine: null,
    menuAiuto: null,
    menuInfoAz: null,
    DtConsegna: null
}

const getters =  {
    getResources: (state) => (lang) => {
        if(state.resources == null){
            let res = JSON.parse(localStorage.getItem("res-Wikini"));
            if(res != null)
                return res[lang];
        }else{
            return  state.resources[lang];
        }
    },
    getReload: (state) =>{
        return state.reload;
    },
    getMessageCart: (state) =>{
        if(state.message == null){
            let msg = JSON.parse(localStorage.getItem("msgCart-Wikini"));
            return msg;
        }else{
            return state.message;
        }
    },
    getPagine: (state) => {
        return state.pagine ?? JSON.parse(localStorage.getItem("pagine"));
    },
    getMenuAiuto: (state) =>{
        return state.menuAiuto ?? JSON.parse(localStorage.getItem("menuAiuto"));
    },
    getMenuInfoAz: (state) =>{
        return state.menuInfoAz ?? JSON.parse(localStorage.getItem("menuInfo"));
    },
    getDtConsegna: (state) =>{
        return state.DtConsegna ?? JSON.parse(localStorage.getItem("DtConsegna"));
    }
}

const mutations = {
    setResources: (state, resources)  => {
        state.resources = resources;
        localStorage.setItem("res-Wikini", JSON.stringify(state.resources));    
    },
    setReload: (state) =>{
        state.reload = !state.reload;
    },
    setMessageCart: (state, message) =>{
        state.message = message;
        localStorage.setItem("msgCart-Wikini", message);
    },
    setPagine: (state, pagine) => {
        state.pagine = pagine;
        localStorage.setItem('pagine', JSON.stringify(state.pagine));
    },
    setMenuInfo: (state,listaPagine) =>{
        state.menuInfoAz = listaPagine;
        localStorage.setItem('menuInfo',JSON.stringify(state.menuInfoAz));
    },
    setMenuAiuto: (state,listaPagine) =>{
        state.menuAiuto = listaPagine;
        localStorage.setItem('menuAiuto',JSON.stringify(state.menuAiuto));
    },
    setInCall: (state, value) => {
        state.inCall = value;
    },
    setDtConsegna: (state, value) => {
        state.DtConsegna = value;
        localStorage.setItem('DtConsegna',JSON.stringify(state.DtConsegna));
    }
}

const actions = {
    getResourcesFromApi: ({commit}, data) => {     
        return WebApiService.getRisorse(data.Shop, data.Lang).then(
            (data) => {  
                if(data){
                    commit('setResources'
                    , data.Data);
                    return true;
                }
                else{
                    return false;
                }                            
            }          
        ).catch(
            error => {return false}
        );
    },
    GetPagineApi: ({commit},data) =>{
        return WebApiService.getWLang("Pagine/PagineAll", data.Token, null, data.Lingua).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    let listaMenu = data.Data;
                    let listaPagineInfo = listaMenu.filter((item) =>{
                        return item.CodiceMenu == "0001";
                    });
                    let listaPagineAiuto = listaMenu.filter((item) =>{
                        return item.CodiceMenu == "0002";
                    });
                    commit('setMenuAiuto',listaPagineAiuto);
                    commit('setMenuInfo',listaPagineInfo);
                    commit("setPagine", data.Data);
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    },
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}