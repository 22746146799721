import CarrelloTestata from "@/models/Carrello/carrelloTestata.model.js";

export default class Carrello{
    Testata;
    Righe;

    constructor(){
        this.Testata = new CarrelloTestata();
        this.Righe = []; 
    }
}